import { profile } from "@/api/endpoints/profile.js";

const initialState = ({permissions: []});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
  },
  actions: {
    async fetchProfile({ commit }) {
      const response = await profile()
      commit('setPermissions', response.data.data.permissions || []);
    },
  },
  getters: {
    can: (state) => (permission) => {
      return state.permissions.includes(permission);
    },
  },
};
