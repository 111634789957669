<template>
  <v-app>
    <router-view></router-view>
    <TheConfirmationDialog/>
  </v-app>
</template>

<script lang="js">
import TheConfirmationDialog from '@/components/TheConfirmationDialog.vue';
export default {
  name: 'App',
  components: { TheConfirmationDialog },
};
</script>
