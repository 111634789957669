export default {
  path: '',
  component: () => import('@/templates/Authorisation'),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import('@/views/authorisation/Login.vue'),
    },
    {
      path: 'auth/callback',
      name: 'auth.callback',
      component: () => import('@/views/authorisation/AuthorisationCallback.vue'),
    }
  ],
};
