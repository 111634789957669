import Vue from 'vue';
import Router from 'vue-router';
import authorisation from './routes/authorisation.js';
import authorised from './routes/authorised.js';

Vue.use(Router);

const routes = [
  {
    path: '',
    redirect: { name: 'home' },
  },
  authorisation,
  authorised,
  {
    component: () => import('@/templates/Default'),
    path: '',
    children: [
      {
        path: '/niet-gevonden',
        name: '404',
        component: () => import('@/views/PageNotFound.vue')
      },
      {
        path: '/geen-toegang',
        name: '403',
        component: () => import('@/views/PageForbidden.vue')
      },
      {
        path: '*',
        redirect: '404',
      }
    ]
  }
];

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
});
